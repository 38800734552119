@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Sora, system-ui, sans-serif;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f4f6;
}

#root {
  height: 100%;
}

.map {
  height: 100%;
  border: 0
}